import ElementHeader from "../elementHeader/ElementHeader";
import "./about.css";
const About = () => {
  return (
    <div className={"aboutWrap"} id={"01."}>
      <ElementHeader num={"01."} text={"About"} color={"#191919"} />
      <div className="aboutInner">
        <p>
          With the realisation that I enjoy web development more than the other
          topics I had touched upon during my university studies, I decided to
          go all in with my focus on the subject.{" "}
        </p>
        <p>
          I spent the last year of the pandemic learning everything I could, and
          built this webpage to showcase some of the projects I have created
          during this time.
        </p>
        <p>
          I started from the bottom, creating simple web pages with HTML and
          CSS, before moving onto Javascript based apps, and finally to more
          complex applications created with React.
        </p>
        <p>
          My next step is to focus on learning Node.js so I can create backends
          for my applications rather than having to rely on Firebase.{" "}
        </p>
        <p>
          When I'm not programming you can find me trying (and failing) to climb
          out of platinum on Overwatch.
        </p>
        <p>Other interests include photography, travel and cycling.</p>
      </div>
    </div>
  );
};

export default About;
