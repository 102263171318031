import useShowHeader from "../../hooks/useShowHeader";
import "./header.css";
import HeaderLink from "./HeaderLink";

const Header = () => {
  const { showHeader, isTop } = useShowHeader();

  return (
    <div
      style={{
        top: showHeader ? 0 : "-2em",
        opacity: !showHeader && 0,
        boxShadow: !isTop && "rgba(56, 56, 56, 0.35) 0px 5px 15px",
      }}
      className={"header"}
    >
      <div className="logo">
        <p style={{ color: "#199515" }}>Hello World</p>
        <p style={{ color: "#146C11" }}>Hello World</p>
        <p style={{ color: "#145B11" }}>Hello World</p>
        <p style={{ color: "#0E4E0B" }}>Hello World</p>
      </div>
      <div className={"headerLinks"}>
        {" "}
        <HeaderLink num={"01."} text={"About"} />
        <HeaderLink num={"02."} text={"Projects"} />
        <HeaderLink num={"03."} text={"Skills"} />
      </div>
    </div>
  );
};

export default Header;
