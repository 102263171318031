import "./elementHeader.css";

const ElementHeader = (props) => {
  return (
    <div className="elementHeader" style={{ color: props.color }}>
      <div
        className="headerLine"
        style={{ justifySelf: "end", backgroundColor: props.color }}
      ></div>
      <p>{props.num + " " + props.text}</p>
      <div
        className="headerLine"
        style={{ justifySelf: "start", backgroundColor: props.color }}
      ></div>
    </div>
  );
};

export default ElementHeader;
