import ElementHeader from "../elementHeader/ElementHeader";
import projects from "../../data/projectData";
import "./projects.css";
import ProjectElement from "../projectElements/ProjectElement";

const Projects = () => {
  const projectElements = projects.map((item) => {
    return (
      <ProjectElement
        name={item.name}
        src={item.imageSrc}
        desc={item.about}
        demo={item.liveDemo}
        git={item.git}
      />
    );
  });
  return (
    <div className="projectPage" id={"02."}>
      <ElementHeader num={"02."} text={"Recent Projects"} color={"#199515"} />
      <div className="projectWrap">{projectElements}</div>
    </div>
  );
};

export default Projects;
