import { Link } from "react-scroll";

const HeaderLink = (props) => {
  return (
    <div className="linkWrap">
      <Link to={props.num} spy={true} smooth={true}>
        {props.num + props.text}
      </Link>
    </div>
  );
};

export default HeaderLink;
