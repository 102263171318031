import photogram from "./images/photogram.png";
import pawprints from "./images/pawprints.png";
import weather from "./images/weather.png";
const projects = [
  {
    name: "PhotoGram",
    about:
      "A simple instagram style website built using React, with a Firebase backend.",
    imageSrc: photogram,
    liveDemo: "https://instagram-43c3f.firebaseapp.com/",
    git: "https://github.com/Legandjl/ig_clone",
  },

  {
    name: "Weather App",
    about: "A weather app using openweather api built with vanilla JS.",
    imageSrc: weather,
    liveDemo: "https://legandjl.github.io/weather-app/",
    git: "https://github.com/Legandjl/weather-app",
  },

  {
    name: "PawPrints",
    about: "A cat themed storefront built in React.",
    imageSrc: pawprints,
    liveDemo: "https://legandjl.github.io/shopping-cart/",
    git: "https://github.com/Legandjl/shopping-cart",
  },
];

export default projects;
