import { useEffect, useState } from "react";

const useShowHeader = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [isTop, setIsTop] = useState(true);

  const [prevScrollPos, setPrev] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsTop(currentScrollPos === 0);
      if (prevScrollPos > currentScrollPos) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
      setPrev(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, showHeader]);

  return { showHeader, isTop };
};

export default useShowHeader;
