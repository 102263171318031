import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <p>Designed by Dorian Legan</p>
      <a
        style={{ color: "inherit", textDecoration: "none", paddingTop: 3 }}
        target="_blank"
        href={"https://github.com/Legandjl"}
        rel="noreferrer"
      >
        <i class="ri-github-fill"></i>
      </a>{" "}
      <p style={{ justifySelf: "end" }}>dorianlegan@protonmail.com</p>
    </div>
  );
};

export default Footer;
