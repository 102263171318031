import About from "../about/About";
import Header from "../header/Header";
import Intro from "../intro/Intro";
import Projects from "../projects/Projects";
import "remixicon/fonts/remixicon.css";
import "./App.css";
import Contact from "../contact/contact";
import Footer from "../footer/Footer";
import Skills from "../skills/Skills";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Intro />
      <About />
      <Projects />
      <Skills />

      <Footer />
    </div>
  );
};

export default App;
