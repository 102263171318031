import ElementHeader from "../elementHeader/ElementHeader";
import "./skills.css";
const Skills = () => {
  return (
    <div className="skillsWrap" id={"03."}>
      <ElementHeader num={"03."} text={"Skills"} color={"#191919"} />
      <div className="skillsInner">
        <p>
          I have completed modules covering a multitude of topics and through
          this I have gained experience in a variety of programming languages.{" "}
        </p>
        <p>
          So far I am comfortable with Python, Java, PHP, HTML, CSS5, and of
          course Javascript.
        </p>
        <p>
          {" "}
          Before I went all in with learning web development my primary focus
          was Java, which was the first language I was introduced to via my
          university. Through my completion of multiple MOOCS, modules and
          projects I gained a strong understanding of OOP principles and
          programming best practices.
        </p>
        <p>
          {" "}
          Other tools and technologies I have experience with include: Jest,
          Github, Git, ESlint, Webpack and MySQL.
        </p>
      </div>
    </div>
  );
};

export default Skills;
