import "./intro.css";
const Intro = () => {
  return (
    <div className={"introWrap"}>
      <div className="introInner">
        <p className={"introName"} style={{ color: "#199515" }}>
          Hello, my name is
        </p>
        <h2>Dorian Legan.</h2>
        <h2 style={{ opacity: 0.7 }}>I like building websites.</h2>
        <p style={{ fontSize: "0.6em", color: "#DAEFDA", width: "25em" }}>
          I'm a final year student who after a lot of experimentation, realised
          that this is what I want to do for a career.
        </p>
      </div>
    </div>
  );
};

export default Intro;
