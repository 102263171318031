import ProjectImage from "./ProjectImage";
import "./projectElement.css";
import { useState } from "react";

const ProjectElement = (props) => {
  const [ishovered, setIsHovered] = useState(false);
  //image needs to show div overlay on hover with info
  return (
    <div className="projectElementWrap" style={{ gridColumn: 2 }}>
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        className="imageLinkOverlay"
      >
        {ishovered && (
          <a
            style={{ color: "inherit" }}
            target="_blank"
            href={props.demo}
            rel="noreferrer"
          >
            <div className="projectTitle">{props.name}</div>
          </a>
        )}
        {ishovered && <div className="projectDesc">{props.desc}</div>}
        {ishovered && (
          <a
            style={{ color: "inherit", textDecoration: "none" }}
            target="_blank"
            href={props.git}
            rel="noreferrer"
          >
            <i class="ri-github-fill"></i>
          </a>
        )}
      </div>
      <ProjectImage src={props.src} name={props.name} />
    </div>
  );
};

export default ProjectElement;
